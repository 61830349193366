






























































import Base from '@/mixins/Base.vue';
import { IRole, IRoleResponse } from '@/interfaces/role';

const component = Base.extend({
    data() {
        return {

            active: false,
            role: {} as IRole,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        save(): void {
            this.post<IRoleResponse>('roles', {
                ...this.role,
            }).then(({ data }) => {
                this.$router.push({ name: 'roles.edit', params: { id: data.data.id.toString() } });
            });
        },
    },
});

export default component;
